import React from "react";
import Banner from "../component/Banner";
import Advancon from "../component/Advancon";
import Caterogy from "../component/Caterogy";
import News from "../component/News";
import AboutUs from "../component/AboutUs";

const Index = () => {
  return (
    <div>
      <Banner />
      <Advancon />
      <Caterogy />
      {/* <News />
      <AboutUs /> */}
    </div>
  );
};

export default Index;
